import {
    AdvertisementsListingFilterParametersDto,
} from 'modules/api/generated';
import { orderEnumToDtoRecord } from 'modules/api/clients/advertisement/listing/enums/records/order/orderEnumToDtoRecord';
import { IAdvertisementListingFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import {
    mainCategoryEnumsToDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/toDto/mainCategoryEnumsToDtoRecord';
import {
    subCategoryEnumsToDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/sub/toDto/subCategoryEnumsToDtoRecord';
import {
    transactionListingEnumsToDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/transaction/toDto/transactionListingEnumsToDtoRecord';

export const mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto =
    (
        filter: IAdvertisementListingFilterData,
    ): AdvertisementsListingFilterParametersDto => ({
        order: orderEnumToDtoRecord[filter.order],
        ...(!!filter.areaTo && ({ areaTo: filter.areaTo })),
        ...(!!filter.priceTo && ({ priceTo: filter.priceTo })),
        ...(!!filter.fulltext && ({ fulltext: filter.fulltext })),
        ...(!!filter.areaFrom && ({ areaFrom: filter.areaFrom })),
        ...(!!filter.priceFrom && ({ priceFrom: filter.priceFrom })),
        ...(!!filter.locations && ({ locations: filter.locations })),
        ...(!!filter.onlyWithVideo && ({ onlyWithVideo: filter.onlyWithVideo })),
        ...(!!filter.onlyNewbuilding && ({ onlyNewbuilding: filter.onlyNewbuilding })),
        ...(!!filter.transaction && ({ transaction: transactionListingEnumsToDtoRecord[filter.transaction] })),
        ...(!!filter.categories && ({ categories: filter.categories.map((filterCategory) =>
            mainCategoryEnumsToDtoRecord[filterCategory] || subCategoryEnumsToDtoRecord[filterCategory])
        })),
    });
