import { create } from 'zustand';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { IAutocompleteOption } from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';

type LocationStore = {
    locationQuery: string;
    selectedLocationNames: string;
    selectedLocations: ILocation[];
    locationOptionsInitial:  ILocation[];
    autocompleteOptions: IAutocompleteOption[];
    changeLocationQuery: (query: string) => void;
    onSelectLocation: (location: ILocation) => void;
    onDeselectLocation: (locationId: string) => void;
    setSelectedLocations: (locations: ILocation[]) => void;
    setAutocompleteOptions: (options: IAutocompleteOption[]) => void;
    setLocationOptionsInitial: (optionsInitial: ILocation[]) => void;
};

export const useLocationStore = create<LocationStore>((set) => ({
    locationQuery: '',
    selectedLocations: [],
    autocompleteOptions: [],
    selectedLocationNames: '',
    locationOptionsInitial: [],
    changeLocationQuery: (query: string) => set(() => ({ locationQuery: query })),
    setSelectedLocations: (locations) => set(() => {
        return {
            selectedLocations: locations,
            selectedLocationNames: locations.map((location) => location.name).join(', '),
        };
    }),

    onSelectLocation: (location) => set((store) => {
        const alreadySelectedLocation =
            store.selectedLocations.find(
                (locationFromStore) => locationFromStore.id === location.id
            );

        if (alreadySelectedLocation) {
            return { ...store };
        }

        const newLocations = [
            ...store.selectedLocations,
            location,
        ];

        return {
            locationQuery: '',
            autocompleteOptions: [],
            selectedLocations: newLocations,
            selectedLocationNames: newLocations.map((location) => location.name).join(', '),
        };
    }),

    onDeselectLocation: (locationId) => set((store) => {
        const locationToDeselect =
            store.selectedLocations.find(
                (locationFromStore) => locationFromStore.id === locationId
            );

        if (locationToDeselect) {
            const locationsFiltered =
                store.selectedLocations.filter(
                    (locationFromStore) => locationFromStore.id !== locationId
                );

            return {
                selectedLocations: locationsFiltered,
                selectedLocationNames: locationsFiltered.map((location) => location.name).join(', '),
            };
        }
    }),

    setAutocompleteOptions: (options: IAutocompleteOption[]) => set(() => ({ autocompleteOptions: options })),
    setLocationOptionsInitial: (optionsInitial: ILocation[]) => set(() => ({ locationOptionsInitial: optionsInitial }))
}));